import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MicrosoftAuthenticationService } from 'processdelight-angular-components';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { license$ } from '../data/data.observables';
import { AADUser } from '../domain/models/aad-user.model';
import { GroupUser } from '../domain/models/group-user.model';
import { UserLicenseInfo } from '../domain/models/user-license-info.model';
@Injectable({
  providedIn: 'root',
})
export class FunctionsService {
  apiBase = `${environment.ishtarFunctions}/api`;
  constructor(
    private httpClient: HttpClient,
    private msal: MicrosoftAuthenticationService
  ) {}
  private createApiEndpointUrl(path: string) {
    const url = new URL(`${this.apiBase}/${path}`);
    if (environment.ishtarFunctionsKey.trim() !== '')
      url.searchParams.append('code', environment.ishtarFunctionsKey);
    return url.toString();
  }
  getLicense() {
    return this.httpClient.get<UserLicenseInfo>(
      this.createApiEndpointUrl(
        `license/${this.msal.tenantId}/${this.msal.email}`
      )
    );
  }
  getTranslations() {
    return this.httpClient.get<any>(
      this.createApiEndpointUrl(
        `ishtarapps/translations?lang=${license$.value?.language}`
      )
    );
  }
  getUsers() {
    return this.httpClient
      .get<GroupUser[]>(
        this.createApiEndpointUrl(`users/${this.msal.tenantId}/Ishtar.DMS`)
      )
      .pipe(
        map((user) =>
          user.map(
            (u) =>
              new GroupUser({
                user: new AADUser(this.camelcaseKeys((u as any).User!)),
              })
          )
        )
      );
  }
  getGroups() {
    return this.httpClient
      .get<GroupUser[]>(
        this.createApiEndpointUrl(`groups/${this.msal.tenantId}/Ishtar.DMS`)
      )
      .pipe(
        map((group) => group.map((g) => new GroupUser(this.camelcaseKeys(g))))
      );
  }

  // HELPERS
  camelcaseKeys(obj: any): any {
    if (Array.isArray(obj)) return [...obj.map((o) => this.camelcaseKeys(o))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,
          [e[0].charAt(0).toLowerCase() + e[0].slice(1)]: this.camelcaseKeys(
            e[1]
          ),
        }),
        {}
      );
    else return obj;
  }
  capitalizeKeys(obj: any, ...ignoredProperties: string[]): any {
    const ignoredPropertiesLower = ignoredProperties.map((p) =>
      p.toLowerCase()
    );
    if (Array.isArray(obj))
      return [...obj.map((o) => this.capitalizeKeys(o, ...ignoredProperties))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,
          [e[0].charAt(0).toUpperCase() + e[0].slice(1)]:
            ignoredPropertiesLower.includes(e[0].toLowerCase())
              ? e[1]
              : this.capitalizeKeys(e[1], ...ignoredProperties),
        }),
        {}
      );
    else return obj;
  }
}
