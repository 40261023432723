import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  GeneralLayoutComponent,
  SideBarAction,
} from 'processdelight-angular-components';
import { filter, first, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { license$ } from './core/data/data.observables';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [CommonModule, RouterModule, GeneralLayoutComponent],
})
export class AppComponent implements OnInit {
  appName = 'Ishtar.Visitor!';

  appLogo = environment.cdnAppIconUrl.replace('color', 'Blauw_Wit');

  orgLogo$ = license$.pipe(map((o) => o?.logo));

  userName$ = license$.pipe(map((u) => u?.name));
  userPhoto$ = license$.pipe(map((u) => u?.photo));

  navColor$ = license$.pipe(map((u) => u?.navColor));
  navContrast$ = license$.pipe(map((u) => u?.navContrast));

  actions: SideBarAction[] = [];

  ngOnInit() {
    const params = new URLSearchParams(location.search);
    if (params.has('appColor'))
      this.appLogo = environment.cdnAppIconUrl.replace(
        'color',
        params.get('appColor')!
      );
    const tabIconLink = document.querySelector(
      "link[rel~='icon']"
    ) as HTMLLinkElement;
    if (tabIconLink) tabIconLink.href = this.appLogo;
    license$
      .pipe(
        filter((l) => !!l && !!Object.keys(l).length),
        first()
      )
      .subscribe((l) => {
        if (l?.licenses.some((i) => i.productName == 'Ishtar.Visitor'))
          this.actions.push(
            new SideBarAction({
              title: 'Home',
              icon: 'home',
              iconOutline: true,
              route: ['home'],
            })
          );
        if (
          l?.licenses.some(
            (i) => i.productName == 'Ishtar.Visitor' && i.isAdmin
          )
        )
          this.actions.push(
            new SideBarAction({
              title: 'Settings',
              icon: 'settings',
              iconOutline: true,
              route: ['settings', 'general'],
            })
          );
      });
  }
}

export const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('processdelight-angular-components').then((m) => m.MsalModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];
