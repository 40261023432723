import { AADUser } from './aad-user.model';
import { Group } from './group.model';

export class GroupUser {
  user?: AADUser;
  group?: Group;
  get displayName() {
    return this.user ? this.user?.displayName : this.group?.displayName;
  }
  get id() {
    return this.user ? this.user.id : this.group?.groupId;
  }
  constructor(obj: Partial<GroupUser>) {
    Object.assign(this, obj);
  }

  equals(other: GroupUser) {
    return (
      this.user?.id == other.user?.id &&
      this.group?.groupId == other.group?.groupId
    );
  }
}
