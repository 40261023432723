import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from 'processdelight-angular-components';
import { catchError, filter, first, forkJoin, switchMap } from 'rxjs';
import {
  groups$,
  license$,
  translations$,
  users$,
} from '../data/data.observables';
import { FunctionsService } from './functions.service';
@Injectable({
  providedIn: 'root',
})
export class StartUpService {
  constructor(
    private functions: FunctionsService,
    private loader: LoaderService,
    private router: Router
  ) {}
  getLicense() {
    const id = this.loader.startLoading('Retrieving license information');
    this.functions
      .getLicense()
      .pipe(
        catchError((error) => {
          this.router.navigate(['/401']);
          throw error;
        })
      )
      .subscribe((data) => {
        license$.next(data);
        this.loader.stopLoading(id);
      });
  }
  getUsers() {
    const id = this.loader.startLoading('Retrieving users');
    this.functions.getUsers().subscribe((data) => {
      users$.next(data);
      this.loader.stopLoading(id);
    });
  }
  getGroups() {
    const id = this.loader.startLoading('Retrieving groups');
    this.functions.getGroups().subscribe((data) => {
      groups$.next(data);
      this.loader.stopLoading(id);
    });
  }

  boot() {
    this.getLicense();
    return license$.asObservable().pipe(
      filter((license) => !!license),
      first(),
      switchMap((license) => {
        if (!license?.licenses.some((l) => l.productName == 'Ishtar.Visitor')) {
          this.router.navigate(['/401']);
          throw new Error('No license found');
        }
        this.getUsers();
        this.getGroups();
        return forkJoin([
          translations$.pipe(
            filter((t) => !!Object.keys(t).length),
            first()
          ),
        ]);
      })
    );
  }
}
