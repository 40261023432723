// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  instance: 'https://login.microsoftonline.com/',
  clientId: '17f621ea-f6ba-4284-8a68-ce170447b798',
  tenantId: 'common',
  authority: 'https://login.microsoftonline.com/common',
  ishtarFunctions: 'https://functions.dev.ishtar365.com',
  ishtarFunctionsScope:
    'api://a5341d23-1ecf-48e9-9fc6-55fae87ed91e/user_impersonation',
  ishtarFunctionsKey:
    'Rl1x2qHDmM4-SvoNyzQDb-SRDTv-qXStKozJkQlMzF9lAzFu6Qk4VA==',
  ishtar365: 'https://portal.dev.ishtar365.com',
  appIdentifier: 'visitor.dev',
  lastRouteKey: 'Ishtar.Visitor_Last_Route',
  cdnAppIconUrl:
    'https://ishtar365storage.blob.core.windows.net/icons/Visitor_color.svg',
  cdnImageNotFound:
    'https://ishtar365storage.blob.core.windows.net/icons/not_found.svg',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
