export class AADUser {
  id!: string;
  businessPhones?: string[];
  city?: string;
  companyName?: string;
  country?: string;
  department?: string;
  displayName!: string;
  givenName?: string;
  jobTitle?: string;
  mail!: string;
  mobilePhone?: string;
  officeLocation?: string;
  postalCode?: string;
  preferredLanguage?: string;
  streetAddress?: string;
  surname?: string;
  userPrincipalName!: string;
  aboutMe?: string;
  photo?: string;
  birthday?: Date;
  constructor(obj: Partial<AADUser>) {
    Object.assign(this, obj);
  }
}