import { BehaviorSubject } from 'rxjs';
import { GroupUser } from '../domain/models/group-user.model';
import { UserLicenseInfo } from '../domain/models/user-license-info.model';

export const license$ = new BehaviorSubject<UserLicenseInfo | undefined>(
  undefined
);
export const users$ = new BehaviorSubject<GroupUser[] | undefined>(undefined);
export const groups$ = new BehaviorSubject<GroupUser[] | undefined>(undefined);
export const translations$ = new BehaviorSubject<any>({});
